import React, { FC, ReactElement, useCallback } from 'react'
import Image from '@sport1/news-styleguide/Image'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Touchable from '@sport1/news-styleguide/Touchable'
import GridLayout from '@sport1/news-styleguide/GridLayout'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { SalesTeaserItemProps } from '@sport1/types/web'
import { transformImageUrlToSize } from '@/helpers/imageHelper'

type Props = {
    teaser: SalesTeaserItemProps[]
}

const TVSalesTeaser: FC<Props> = ({ teaser }) => {
    const getImageComponent = useCallback(
        (index: number): ReactElement => {
            if (teaser.length > index) {
                const teaserItem = teaser[index]
                const { slug } = teaserItem
                return (
                    <Touchable
                        href={slug.startsWith('http') ? slug : `https://tv.sport1.de${slug}`}
                    >
                        <Image
                            key={teaserItem.slug}
                            size="L"
                            imageUrl={transformImageUrlToSize(teaserItem.imageUrl, 1200, 0)}
                            radiusType="rounded"
                            alt={teaserItem.slug}
                        />
                    </Touchable>
                )
            }
            return <div />
        },
        [teaser]
    )

    return (
        <NonFlexingContainer testID="tv-sales-teaser" backgroundColor="pearl">
            <NonFlexingContainer
                marginRight={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-10']}
                marginLeft={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-10']}
                spaceTop="standard"
                backgroundColor="pearl"
            >
                <CardHeadline type="DEFAULT" text="LIVESTREAM PAKETE" webHeadlineType="h4" />
                <GridLayout
                    columnType="50-50"
                    firstColumn={getImageComponent(0)}
                    secondColumn={getImageComponent(1)}
                    spaceBetween="standard"
                    spaceY="standard"
                />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default TVSalesTeaser
